import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loading, { LoadingBall } from '@ingka/loading';
import { groupBy, mapObjIndexed, sortBy, values } from 'ramda';
import { Context as ErrorContext } from 'hooks/contexts/ErrorMessageContext';
import { CountryFlag } from 'components/common/CountryFlag';
import { FlexContainer } from 'components/FlexContainer';
import { getCountryName, isPublicera } from 'opt-util/helpers';
import { useLatestByCountryQuery } from '__generated__/types';
import { MetaDataListTable } from './MetaDataListTable';
import { PageHeader } from 'pages/styles';
import * as Styled from './styles';
import { Features, useFeatureContext } from '../../hooks/contexts/FeatureContext';

export const MetaDataList: React.FC<{ country: string }> = ({ country }) => {
  const { loading, error, data } = useLatestByCountryQuery({ variables: { country } });
  const { goBack } = useHistory();
  const { setErrorMessage } = useContext(ErrorContext);
  const { featureIsActive } = useFeatureContext();

  useEffect(() => {
    if (error) {
      setErrorMessage({ title: 'Error', message: error.message, action: () => goBack() });
    }
  }, [error]);

  if (loading) {
    return (
      <Loading>
        <LoadingBall />
      </Loading>
    );
  }

  if (data) {
    const sortedByLang = sortBy((v) => v.language, data.latestVersionsForCountry);
    const sortedByType = sortBy((v) => v.type, sortedByLang);
    const filteredByType = sortedByType.filter((v) => !isPublicera(v.type));
    const grouped = groupBy((v) => `${v.country}_${v.language}`, featureIsActive(Features.PUBLICERA_METADATA) ? sortedByType : filteredByType);
    const countryLanguageVersions = mapObjIndexed((val, key) => {
      const [country, language] = key.split('_');
      return <MetaDataListTable country={country} language={language} versions={val} key={key} />;
    }, grouped);
    return (
      <>
        <PageHeader>Meta Data</PageHeader>
        <FlexContainer direction="column" gap={1}>
          <Styled.Header>
            <CountryFlag countryCode={country} />
            {getCountryName(country)}
          </Styled.Header>
          {values(countryLanguageVersions)}
        </FlexContainer>
      </>
    );
  }
  return null;
};
