import { createContext, useContext, useState, ReactNode } from 'react';
import { getFeatures } from 'api/features';

export type Feature = {
  name: string;
  active: boolean;
};

export enum Features {
  SITEMAPS = 'sitemaps',
  TRANSLATION = 'translation',
  PIP_CATEGORIES_FILTER = 'pip-categories-filter',
  STATISTICS_OVERVIEW = 'statistics-overview',
  PUBLICERA_METADATA = 'publicera-metadata',
}

export type FeatureContextType = {
  features: Feature[];
  updateFeatureList: (token: string) => void;
  featureIsActive: (feature?: Features | string) => boolean;
  isFeatureDisabled: (feature: Features) => boolean;
};

const FeatureContext = createContext<FeatureContextType>({
  features: [],
  updateFeatureList: () => {},
  featureIsActive: (feature?: Features | string) => false,
  isFeatureDisabled: (feature) => false,
});

const FeatureProvider = ({ injectedFeatures, children }: { injectedFeatures?: Feature[]; children: ReactNode }) => {
  const [features, setFeatures] = useState<Feature[]>(injectedFeatures || []);

  const updateFeatureList = async (token: string) => {
    setFeatures(await getFeatures(token));
  };

  const featureIsActive = (feature?: Features | string) => {
    return features.some((f) => f.name === feature);
  };

  const isFeatureDisabled = (feature?: Features) => {
    return !features.some((f) => f.name === feature && !f.active);
  };

  return <FeatureContext.Provider value={{ features, updateFeatureList, featureIsActive, isFeatureDisabled }}>{children}</FeatureContext.Provider>;
};

const useFeatureContext = () => {
  return useContext(FeatureContext);
};

export { FeatureProvider, useFeatureContext };
